import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import Header from '../app_components/Header';
import Grid from '../environment-page-components/Grid';
import '../style/pages-style/Reuse.css';
import config from "../config";
import { useAuth } from "../authentication/AuthProvider";
import { Icon } from '@iconify/react';
import { Bar } from 'react-chartjs-2';
import { Chart, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { translations } from "../app_components/Translation";
import { Document, Packer, Paragraph, Table, TableRow, TableCell, ImageRun, Footer, TextRun } from 'docx';
import { saveAs } from 'file-saver'; // Import file-saver
import { FiDownload } from 'react-icons/fi';

Chart.register(BarElement, CategoryScale, LinearScale);

function Reuse({ selectedLanguage }) {
    const [isOpen, setIsOpen] = useState(true);
    const [isHovered, setIsHovered] = useState({ emissions: false, credits: false, graph: false });
    const [isGraphOpen, setIsGraphOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSupplier, setIsSupplier] = useState(false); // Default to supplier
    const [graphData, setGraphData] = useState(null);
    const { user } = useAuth();
    const [gridApi, setGridApi] = useState(null);
    const gridRef = useRef(null);
    const graphRef = useRef(null);
    const [searchText, setSearchText] = useState('');
    const gridApiRef = useRef(null);
    const [showSearch, setShowSearch] = useState(false);
    const [showColumnsModal, setShowColumnsModal] = useState(false);
    const [columnDefs, setColumnDefs] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]); // State to track selected rows

    const selectedText = translations[selectedLanguage]?.reuse || {};

    // Column definitions for Supplier Type
    const columnDefsSupplier = [
        {
            headerName: selectedText.product || "Product",
            field: "product",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            cellStyle: { textAlign: 'left' },
        },
        {
            headerName: selectedText.quantity || "Credit Quantity",
            field: "credit_quantity",
            editable: true,
            cellStyle: { textAlign: 'right' },
            headerClass: "ag-right-aligned-header"
        },
        {
            headerName: selectedText.donorCredit || "Donor Credit (kg CO₂e)",
            field: "donor_credit",
            cellStyle: { textAlign: 'right' },
            headerClass: "ag-right-aligned-header"
        },
        {
            headerName: selectedText.receiverCredit || "Receiver Credit (kg CO₂e)",
            field: "receiver_credit",
            cellStyle: { textAlign: 'right' },
            headerClass: "ag-right-aligned-header"
        }
    ];

    // Column definitions for Utilizer Type
    const columnDefsUtilizer = [
        {
            headerName: selectedText.designInstallation || "Design Installation",
            field: "design_installation",
            checkboxSelection: true,
            headerCheckboxSelection: true,
            cellStyle: { textAlign: 'left' },
        },
        {
            headerName: selectedText.quantity || "Credit Quantity",
            field: "credit_quantity",
            editable: true,
            cellStyle: { textAlign: 'right' },
            headerClass: "ag-right-aligned-header"
        },
        {
            headerName: selectedText.donorCredit || "Donor Credit (kg CO₂e)",
            field: "donor_credit",
            cellStyle: { textAlign: 'right' },
            headerClass: "ag-right-aligned-header"
        },
        {
            headerName: selectedText.receiverCredit || "Receiver Credit (kg CO₂e)",
            field: "receiver_credit",
            cellStyle: { textAlign: 'right' },
            headerClass: "ag-right-aligned-header"
        }
    ];

    useEffect(() => {
        setColumnDefs(isSupplier ? columnDefsSupplier : columnDefsUtilizer);
    }, [isSupplier, selectedText]);

    // Function to sort rows alphabetically by name and numerically by year
    const sortRows = (data) => {
        return data.sort((a, b) => {
            const nameA = isSupplier ? a.product?.toLowerCase() : a.design_installation?.toLowerCase();
            const nameB = isSupplier ? b.product?.toLowerCase() : b.design_installation?.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return a.Year - b.Year; // Sort by year numerically
        });
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        gridRef.current = params.api; // Use ref to store the API directly
        gridApiRef.current = params.api; // Store the API in another ref for consistency
        params.api.sizeColumnsToFit(); // Adjust columns on grid initialization
    };


    const toggleContainer = () => {
        setIsOpen(!isOpen);
    };

    const toggleGraphContainer = () => {
        setIsGraphOpen(!isGraphOpen);
    };

    const fetchFilteredData = useCallback(async () => {
        if (!user) {
            console.error('User is not authenticated or user ID is missing.');
            return;
        }

        console.log('Fetching data for user:', user.username);

        setIsLoading(true);
        try {
            // Step 1: Fetch the products or design installations for the user
            const response = await fetch(`${config.apiUrl}/filter-data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ is_supplier: isSupplier, user_id: user.username })
            });

            const text = await response.text();
            if (!response.ok) {
                console.error(`Server error: ${response.status} ${response.statusText} - ${text}`);
                throw new Error(`Server error: ${response.status} ${response.statusText} - ${text}`);
            }

            const data = JSON.parse(text);
            const selectedValues = data.map(row => row.id);
            console.log('Data:', data);

            if (selectedValues.length === 0) {
                console.warn('No products or design installations found for the current user.');
                setIsLoading(false);
                return;
            }

            // Step 2: Fetch donor and receiver credits for the selected products or designs
            const comparisonType = isSupplier ? 'Supplier' : 'Design';
            const creditsResponse = await fetch(`${config.apiUrl}/get_donor_receiver_credit?comparison_type=${comparisonType}&selected_values=${selectedValues.join(',')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const creditsText = await creditsResponse.text();
            if (!creditsResponse.ok) {
                console.error(`Server error: ${creditsResponse.status} ${creditsResponse.statusText} - ${creditsText}`);
                throw new Error(`Server error: ${creditsResponse.status} ${creditsResponse.statusText} - ${creditsText}`);
            }

            const creditsData = JSON.parse(creditsText);
            console.log('Credits Data:', creditsData);

            // Step 3: Merge the credits data with the original rowData and calculate total credits
            const mergedData = data.map(row => {
                const id = row.id; // Adjust field name if necessary
                const quantity = row.credit_quantity || 0;
                const creditQuantity = row.credit_quantity || 0;
                const donorCredit = creditsData[id]?.['Donor Credit'] || 0;
                const receiverCredit = creditsData[id]?.['Receiver Credit'] || 0;

                return {
                    ...row,
                    creditQuantity,
                    donor_credit: (donorCredit * creditQuantity).toFixed(2), // Multiply donor credit by quantity
                    receiver_credit: (receiverCredit * creditQuantity).toFixed(2) // Multiply receiver credit by quantity
                };
            });

            // Apply sorting
            const sortedData = sortRows(mergedData);

            setRowData(sortedData);
            console.log('Sorted data with credits:', sortedData);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [isSupplier, user]);

    const onCellValueChanged = (params) => {
        const { data } = params;
        console.log('Edited row data:', data);
        // You can perform any further calculations or updates here if necessary
        // For example, recalculating credits based on the updated credit_quantity:
        const donorCredit = (data.donor_credit / data.credit_quantity) || 0; // Adjust the formula if needed
        const receiverCredit = (data.receiver_credit / data.credit_quantity) || 0; // Adjust the formula if needed

        data.donor_credit = (donorCredit * data.credit_quantity).toFixed(2);
        data.receiver_credit = (receiverCredit * data.credit_quantity).toFixed(2);

        // You might need to update the rowData state to reflect the changes
        setRowData(prevData =>
            prevData.map(row => (row.id === data.id ? data : row))
        );
    };

    const generateGraph = (selectedRows) => {
        if (selectedRows.length === 0) {
            alert(selectedText.selectAtLeastOne || 'Please select at least one product or design installation.');
            return;
        }

        const x_data = selectedRows.map(row => isSupplier ? row.product : row.design_installation);
        const donor_credits = selectedRows.map(row => row.donor_credit);
        const receiver_credits = selectedRows.map(row => row.receiver_credit);

        const graphData = {
            labels: x_data,
            datasets: [
                {
                    label: selectedText.donorCredits || 'Donor Credits',
                    data: donor_credits,
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                    borderColor: 'rgba(54, 162, 235, 1)',       // Border color without transparency
                    borderWidth: 1,
                },
                {
                    label: selectedText.receiverCredits || 'Receiver Credits',
                    data: receiver_credits,
                    backgroundColor: 'rgba(255, 99, 132, 0.6)',
                    borderColor: 'rgba(255, 99, 132, 1)',        // Border color without transparency
                    borderWidth: 1,
                }
            ]
        };

        console.log('Graph data:', graphData);

        setGraphData(graphData);
        setIsGraphOpen(true);
    };

    const onSelectionChanged = (params) => {
        const selectedRows = params.api.getSelectedRows();
        console.log('Selected rows:', selectedRows);

        // Update selected rows state for totals calculation
        setSelectedRows(selectedRows);

        // Generate graph when selection changes
        generateGraph(selectedRows);
    };


    const exportSelectedRowsAsDocx = async () => {
        if (!gridRef.current) {
            alert(selectedText.gridNotReady || 'Grid is not ready');
            return;
        }

        const selectedRows = gridRef.current.getSelectedRows();
        if (selectedRows.length === 0) {
            alert(selectedText.selectAtLeastOne || 'Please select at least one product or design installation.');
            return;
        }

        try {
            // Create the DOCX document with a functional footer
            const doc = new Document({
                sections: [
                    {
                        children: [
                            new Paragraph({
                                text: isSupplier ? selectedText.products || 'Products' : selectedText.designInstallations || 'Design Installations',
                                heading: 'Heading1'
                            }),
                            new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph(isSupplier ? selectedText.product || 'Product' : selectedText.designInstallation || 'Design Installation')] }),
                                            new TableCell({ children: [new Paragraph(selectedText.quantity || 'Quantity')] }),
                                            new TableCell({ children: [new Paragraph(selectedText.donorCredit || 'Donor Credit (kg CO₂e)')] }),
                                            new TableCell({ children: [new Paragraph(selectedText.receiverCredit || 'Receiver Credit (kg CO₂e)')] }),
                                        ],
                                    }),
                                    ...selectedRows.map(row => new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph(isSupplier ? row.product || '' : row.design_installation || '')] }),
                                            new TableCell({ children: [new Paragraph(row.creditQuantity?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.donor_credit?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.receiver_credit?.toString() || '0')] }),
                                        ],
                                    }))
                                ],
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 600 },
                            }),
                            new Paragraph({
                                text: selectedText.generatedGraph || 'Generated Graph',
                                heading: 'Heading2'
                            }),
                            new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: await fetchGraphImage(), // Function to get the graph image
                                        transformation: {
                                            width: 600,
                                            height: 200,
                                        },
                                    }),
                                ],
                            }),
                        ],
                        footers: {
                            default: new Footer({
                                children: [
                                    new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: 'Arivu.2024 | Copyright © 2024 VRTTA Green Solutions. All rights reserved.',
                                                font: 'Arial',
                                                size: 20, // font size
                                                italics: true
                                            })
                                        ],
                                        alignment: 'center', // Center-align the footer text
                                    })
                                ]
                            })
                        }
                    }
                ]
            });

            const blob = await Packer.toBlob(doc);
            saveAs(blob, "reuse-report.docx");
        } catch (error) {
            console.error('Error generating DOCX:', error);
            alert('An error occurred while generating the DOCX file.');
        }
    };

    const fetchGraphImage = () => {
        return new Promise((resolve) => {
            if (graphRef.current) {
                const graphImage = graphRef.current.toBase64Image();
                // Convert base64 image to Blob
                const imageData = graphImage.split(",")[1];
                const byteCharacters = atob(imageData);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                    const slice = byteCharacters.slice(offset, offset + 512);
                    const byteNumbers = new Array(slice.length);

                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }

                const blob = new Blob(byteArrays, { type: 'image/png' });
                resolve(blob);
            } else {
                resolve(new Blob([])); // resolve with an empty blob if no graph is present
            }
        });
    };

    const downloadChartAsImage = (ref, filename) => {
        const chart = ref.current;
        if (chart) {
            const url = chart.toBase64Image();
            const link = document.createElement('a');
            link.href = url;
            link.download = `${filename}.png`;
            link.click();
        }
    };

    useEffect(() => {
        fetchFilteredData();
    }, [fetchFilteredData]);

    const saveUpdatedQuantities = async () => {
        if (!gridRef.current) {
            alert(selectedText.gridNotReady || 'Grid is not ready');
            return;
        }
    
        const allRowsData = [];
        gridRef.current.forEachNode(node => allRowsData.push(node.data));
    
        try {
            // Loop through each row and send an update request
            for (const row of allRowsData) {
                const response = await fetch(`${config.apiUrl}/update_data`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        table_name: isSupplier ? 'suppliers_products' : 'design_installations', // Adjust table name based on type
                        data: {
                            credit_quantity: row.credit_quantity,
                            id: row.id
                        },
                        condition: `id = '${row.id}'`
                    }),
                });
    
                if (!response.ok) {
                    const text = await response.text();
                    console.error(`Server error: ${response.status} ${response.statusText} - ${text}`);
                    throw new Error(`Server error: ${response.status} ${response.statusText} - ${text}`);
                }
            }
    
            // Re-fetch data after successful save
            await fetchFilteredData();
    
            // Reset selected rows to trigger recalculation of totals for all rows
            setSelectedRows([]);
    
            alert(selectedText.saveSuccess || 'Data saved successfully!');
        } catch (error) {
            console.error('Error saving data:', error);
            alert(selectedText.saveError || 'There was an error saving the data.');
        }
    };
    

    // Function to calculate total donor credits
    const calculateTotalDonorCredits = useMemo(() => {
        const data = selectedRows.length > 0 ? selectedRows : rowData;
        return data.reduce((total, row) => {
            const donorCreditValue = parseFloat(row.donor_credit);
            return !isNaN(donorCreditValue) ? total + donorCreditValue : total;
        }, 0).toFixed(2);
    }, [selectedRows, rowData]);

    // Function to calculate total receiver credits
    const calculateTotalReceiverCredits = useMemo(() => {
        const data = selectedRows.length > 0 ? selectedRows : rowData;
        return data.reduce((total, row) => {
            const receiverCreditValue = parseFloat(row.receiver_credit);
            return !isNaN(receiverCreditValue) ? total + receiverCreditValue : total;
        }, 0).toFixed(2);
    }, [selectedRows, rowData]);


    const handleSearch = (event) => {
        setSearchText(event.target.value);
        if (gridApiRef.current) {
            gridApiRef.current.setQuickFilter(event.target.value);
        }
    };

    const toggleSearchBar = () => {
        setShowSearch(!showSearch);
        setSearchText(''); // Clear the search text when closing the search bar
        if (gridApiRef.current) {
            gridApiRef.current.setQuickFilter(''); // Clear the filter when closing the search bar
        }
    };

    const exportCsv = () => {
        if (!gridApi) {
            alert(selectedText.gridNotReady || 'Grid is not ready');
            return;
        }

        // Get the current date and time in the desired format
        const now = new Date();
        const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
        const formattedTime = `${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
        const filename = `reuse-data-${formattedDate}-${formattedTime}.csv`;

        // Export the CSV file with the new filename
        gridApi.exportDataAsCsv({ fileName: filename });
    };



    const toggleColumnVisibility = (field) => {
        if (gridApi) {
            const currentState = gridApi.getColumnState();
            const targetColumn = currentState.find((col) => col.colId === field);
            if (targetColumn) {
                gridApi.setColumnVisible(field, targetColumn.hide);
                setColumnDefs((prevColumnDefs) =>
                    prevColumnDefs.map((col) => {
                        if (col.field === field) {
                            // Toggle the hide property in the columnDefs state
                            return { ...col, hide: !targetColumn.hide };
                        }
                        return col;
                    })
                );
            }
        }
    };

    const columnSelectionModal = () => {
        if (!gridApi) return null;

        return (
            <div className="columns-modal">
                <button className="close-modal-button" onClick={() => setShowColumnsModal(false)}>
                    <Icon icon="mdi:close" style={{ fontSize: '20px', color: 'black' }} />
                </button>
                {columnDefs.map((column) => {
                    if (column.field !== "id") {
                        const colState = gridApi.getColumnState().find((col) => col.colId === column.field);

                        return (
                            <div key={column.field}>
                                <input
                                    type="checkbox"
                                    checked={!colState?.hide}
                                    onChange={() => toggleColumnVisibility(column.field)}
                                />
                                <label>{column.headerName || column.field}</label>
                            </div>
                        );
                    }
                })}
            </div>
        );
    };

    return (
        <div className="reuse">
            <Header selectedLanguage={selectedLanguage} />
            <div className="grid-container-reuse">
                <div className="container-header">
                    <h2
                        onClick={toggleContainer} // Clicking on the text toggles the section
                        onMouseEnter={() => setIsHovered({ ...isHovered, emissions: true })} // Hover effect
                        onMouseLeave={() => setIsHovered({ ...isHovered, emissions: false })}
                        style={{
                            cursor: 'pointer',
                            color: isHovered.emissions ? 'grey' : 'black' // Change color on hover
                        }}
                    >
                        {isSupplier ? selectedText.avoidedEmissions || 'Avoided Emissions' : selectedText.calculatedCredits || 'Calculated Credits'}
                    </h2>
                </div>

                {isOpen && !isLoading && (
                    <div>
                        {/* Moved table-top-button-container inside this block */}
                        <div className="table-top-button-container">
                            {showSearch ? (
                                <div className="search-bar-container">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchText}
                                        onChange={handleSearch}
                                        style={{ padding: '5px', width: '200px', marginRight: '10px' }}
                                    />
                                    <button onClick={() => handleSearch({ target: { value: '' } })} style={{ padding: '5px' }}>
                                        Clear
                                    </button>
                                    <button
                                        onClick={toggleSearchBar}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            cursor: 'pointer',
                                            marginLeft: '5px'
                                        }}
                                    >
                                        <Icon icon="mdi:close" style={{ fontSize: '20px', color: 'black' }} />
                                    </button>
                                </div>
                            ) : (
                                <div></div>
                            )}

                            {/* Right Buttons Container */}
                            <div className="right-buttons-container">
                                {!showSearch && (
                                    <button className="search-button" onClick={toggleSearchBar} title="Search for keywords in table">
                                        <Icon icon="mdi:magnify" />
                                    </button>
                                )}
                                <button className="show-column-button"
                                    onClick={() => setShowColumnsModal(!showColumnsModal)} title="Show/Hide Columns">
                                    <Icon icon="material-symbols:view-column-sharp" />
                                </button>
                                {showColumnsModal && columnSelectionModal()}
                                <button className="csv-button" onClick={exportCsv} title="Export current table as CSV">
                                    <Icon icon="mdi:file-export" />
                                </button>
                            </div>
                        </div>
                        <Grid
                            columnDefs={columnDefs}
                            rowData={rowData}
                            onGridReady={onGridReady}
                            onSelectionChanged={onSelectionChanged}
                            onCellValueChanged={onCellValueChanged}
                            className="custom-grid-class"
                        />
                        <div className="credits-total">
                            <div className="credits-total-text">
                                <text>{selectedText.totalDonorCredits || 'Total Donor Credits'}: {calculateTotalDonorCredits}</text>
                            </div>
                            <div className="credits-total-text">
                                <text>{selectedText.totalReceiverCredits || 'Total Receiver Credits'}: {calculateTotalReceiverCredits}</text>
                            </div>
                        </div>

                        <div className="button-container-reuse">
                            <button onClick={saveUpdatedQuantities}
                                title="Save Reuse"><Icon icon="mdi:floppy-disk"
                                    style={{ fontSize: '16px' }} /></button>
                        </div>
                    </div>
                )}
                {isOpen && isLoading && <p>{selectedText.loadingData || 'Loading data...'}</p>}
            </div>

            {graphData && (
                <div className="reuse-graph-container">
                    <div className="container-header">
                        <h2
                            onClick={toggleGraphContainer} // Click on text to toggle
                            onMouseEnter={() => setIsHovered({ ...isHovered, graph: true })} // Hover effect
                            onMouseLeave={() => setIsHovered({ ...isHovered, graph: false })}
                            style={{
                                cursor: 'pointer',
                                color: isHovered.graph ? 'grey' : 'black', // Change color on hover
                            }}
                        >
                            {selectedText.generatedGraph || 'Generated Graph'}
                        </h2>

                        <div className="download-icon" onClick={() => downloadChartAsImage(graphRef, 'reuse_graph')}>
                            <Icon icon="fe:download" style={{ color: 'grey', cursor: 'pointer' }} />
                        </div>
                    </div>
                    {isGraphOpen && (
                        <Bar
                            ref={graphRef}
                            data={graphData}
                            options={{
                                scales: {
                                    x: {
                                        type: 'category',
                                        title: {
                                            display: true,
                                            text: isSupplier ? selectedText.product || 'Product' : selectedText.designInstallation || 'Design Installation'
                                        },
                                        grid: {
                                            display: true, // Enable gridlines for the x-axis
                                            borderColor: 'black', // Set the border color for the gridlines
                                        }
                                    },
                                    y: {
                                        beginAtZero: true,
                                        title: {
                                            display: true,
                                            text: selectedText.credits || 'Credits (kg CO₂e)'
                                        },
                                        grid: {
                                            display: true, // Enable gridlines for the y-axis
                                            borderColor: 'black', // Set the border color for the gridlines
                                        }
                                    }
                                },
                                layout: {
                                    padding: 20 // Add some padding around the chart
                                },
                            }}
                            height={100}
                            width={300}
                        />
                    )}
                </div>
            )}

            {/* Inventory Date and Export as DOCX Button Container */}
            <div className="inventory-date-container">
                <button onClick={exportSelectedRowsAsDocx} className="generate-report-reuse-button">
                    <FiDownload size={18} style={{ marginRight: '8px' }} />
                    {selectedText.exportDocx || 'Generate Report'}
                </button>
            </div>
        </div>
    );

}

export default Reuse;
