import React, {useState} from "react";
import Header from "../app_components/Header";
import TableComponent from "../environment-page-components/Table";
import ScopeTable from "../organizational-page-components/ScopeTable";
import '../style/organizational-pages-style/organizationalorganization.css';
import ScopeFootprint from "../organizational-page-components/ScopeFootprint";
import ScopeCategoryDetailsTable from "../organizational-page-components/ScopeCategoryDetailsTable";
import OrganizationalBottomLCA from "../organizational-page-components/OrganizationalBottomLCA";
import {saveAs} from 'file-saver';
import {Document, Packer, Paragraph, Table, TableCell, TableRow, ImageRun, Footer, TextRun} from 'docx';
import {FiDownload} from 'react-icons/fi';
import {translations} from "../app_components/Translation";

function OrganizationalCorporate({selectedLanguage}) {
    const [selectedRowData, setSelectedRowData] = useState([]);
    const [selectedScopeData, setSelectedScopeData] = useState([]);
    const [selectedEmissionDetailsData, setSelectedEmissionDetailsData] = useState([]);
    const [scopeData, setScopeData] = useState([]);
    const [scopeDetailsData, setScopeDetailsData] = useState([]);
    const [scopeTableTotal, setScopeTableTotal] = useState(null);
    const [mainTableGridApi, setMainTableGridApi] = useState(null);
    const [scopeTableGridApi, setScopeTableGridApi] = useState(null);
    const [categoryDetailsTableGridApi, setCategoryDetailsTableGridApi] = useState(null);
    const [totalGWP, setTotalGWP] = useState(null);
    const [trackView, setTrackView] = useState(false);
    // const [structureView, setStructureView] = useState(false);// State to track if track view is open
    const [allTableRows, setAllTableRows] = useState([]);

    const [footprintChart, setFootprintChart] = useState(null);  // Footprint image
    const [footprintDoughnut, setFootprintDoughnut] = useState(null);  // Footprint doughnut chart

    const [LCAData, setLCAData] = useState([]);

    const selectedText = translations[selectedLanguage];

    const scope1Categories = ['Stationary Combustion', 'Mobile Sources', 'Refrigeration / AC Equipment Use', 'Fire Suppression', 'Purchased Gases'];
    const scope2Categories = ['Electricity', 'Steam'];
    const scope3Categories = ['Business Travel', 'Employee Commuting', 'Upstream Transportation and Distribution',
        'Downstream Transportation and Distribution', 'Purchased Goods and Services', 'Capital Goods', 'Fuel and Energy-related Activities',
        'Processing of Sold Products', 'Use of Sold Products', 'End-of-life Treatment of Sold Products', 'Waste', 'Franchises',
        'Investments', 'Upstream Leased Assets', 'Downstream Leased Assets'];
    const scope4Categories = [];

    const organizationalBoundariesDescription = "Which consolidation approach was chosen (check each consolidation " +
        "approach for which your company is reporting emissions.) If your company is reporting according to more than one " +
        "consolidation approach, please complete and attach an additional completed reporting template that provides your " +
        "company’s emissions data following the other consolidation approach(es)";

    const operationalBoundariesScope3 = 'Are Scope 3 emissions included in this inventory?';

    const operationalBoundariesCO2 = 'Direct CO2 emissions from Biogenic combustion (mtCO2 )';

    const baseYearLine1 = 'Year chosen as base year';

    const baseYearLine2 = 'Clarification of company-determined policy for making base year emissions recalculations';

    const baseYearLine3 = 'Context for any significant emissions changes that trigger base year emissions recalculations';

    const exportAsDocx = async () => {
        if (selectedRowData.length !== 1) {
            alert('Please select exactly one corporate from the corporate table to generate the report.');
            return;
        }
        try {
            const doc = new Document({
                sections: [
                    {
                        children: [
                            new Paragraph({
                                text: 'Corporate Emission Report',
                                heading: "Heading1",
                            }),
                            new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph('Corporate')]}),
                                            new TableCell({children: [new Paragraph('Location')]}),
                                            new TableCell({children: [new Paragraph('Year')]}),
                                            new TableCell({children: [new Paragraph('GWP Total (kg CO₂e)')]}),
                                            new TableCell({children: [new Paragraph('Cost')]}),
                                            new TableCell({children: [new Paragraph('Cost per GWP')]}),
                                        ],
                                    }),
                                    ...selectedRowData.map(row => new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(row.corporate || '')]}),
                                            new TableCell({children: [new Paragraph(row.location || '')]}),
                                            new TableCell({children: [new Paragraph(row.Year?.toString() || '')]}),
                                            new TableCell({children: [new Paragraph(row.gwp_total?.toString() || '0')]}),
                                            new TableCell({children: [new Paragraph(row.cost?.toString() || '0')]}),
                                            new TableCell({children: [new Paragraph(row.cost_per_gwp?.toString() || '0')]}),
                                        ],
                                    })),
                                ],
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: {after: 300},
                            }),
                            new Paragraph({
                                text: 'Emission Data',
                                heading: "Heading1",
                            }),
                            new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph('Category')]}),
                                            new TableCell({children: [new Paragraph('Description')]}),
                                            new TableCell({children: [new Paragraph('GWP Total (kg CO₂e)')]}),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph('Scope1')],
                                                columnSpan: 3, // Span across three columns to match the table width
                                            }),
                                        ],
                                    }),
                                    ...scopeData.filter(row => scope1Categories.includes(row.category)).map(row => new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(row.category || '')]}),
                                            new TableCell({children: [new Paragraph(row.description || '')]}),
                                            new TableCell({children: [new Paragraph(row.gwp?.toString() || '0')]}),
                                        ],
                                    })),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph('Scope2')],
                                                columnSpan: 3, // Span across three columns to match the table width
                                            }),
                                        ],
                                    }),
                                    ...scopeData.filter(row => scope2Categories.includes(row.category)).map(row => new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(row.category || '')]}),
                                            new TableCell({children: [new Paragraph(row.description || '')]}),
                                            new TableCell({children: [new Paragraph(row.gwp?.toString() || '0')]}),
                                        ],
                                    })),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph('Scope3')],
                                                columnSpan: 3, // Span across three columns to match the table width
                                            }),
                                        ],
                                    }),
                                    ...scopeData.filter(row => scope3Categories.includes(row.category)).map(row => new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(row.category || '')]}),
                                            new TableCell({children: [new Paragraph(row.description || '')]}),
                                            new TableCell({children: [new Paragraph(row.gwp?.toString() || '0')]}),
                                        ],
                                    })),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph('Scope4')],
                                                columnSpan: 3, // Span across three columns to match the table width
                                            }),
                                        ],
                                    }),
                                    ...scopeData.filter(row => scope4Categories.includes(row.category)).map(row => new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(row.category || '')]}),
                                            new TableCell({children: [new Paragraph(row.description || '')]}),
                                            new TableCell({children: [new Paragraph(row.gwp?.toString() || '0')]}),
                                        ],
                                    })),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph('Total')],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(scopeData.reduce((sum, row) => sum + (row.gwp || 0), 0).toString() || '0')],
                                                columnSpan: 2
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: {after: 300},
                            }),
                            new Paragraph({
                                text: 'Emission Details',
                                heading: "Heading1",
                            }),
                            new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph('Method')]}),
                                            new TableCell({children: [new Paragraph('Type')]}),
                                            new TableCell({children: [new Paragraph('Category')]}),
                                            new TableCell({children: [new Paragraph('Description')]}),
                                            new TableCell({children: [new Paragraph('Quantity')]}),
                                            new TableCell({children: [new Paragraph('Unit')]}),
                                            new TableCell({children: [new Paragraph('Factor')]}),
                                            new TableCell({children: [new Paragraph('Total CO2-eq(kg)')]}),
                                        ],
                                    }),
                                    ...scopeDetailsData.map(row => new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(row.calculation_method || '')]}),
                                            new TableCell({children: [new Paragraph(row.type || '')]}),
                                            new TableCell({children: [new Paragraph(row.category || '')]}),
                                            new TableCell({children: [new Paragraph(row.description || '')]}),
                                            new TableCell({children: [new Paragraph(row.quantity?.toString() || '0')]}),
                                            new TableCell({children: [new Paragraph(row.unit || '')]}),
                                            new TableCell({children: [new Paragraph(row.factor?.toString() || '0')]}),
                                            new TableCell({children: [new Paragraph(row.totalco2eq?.toString() || '0')]}),
                                        ],
                                    })),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph('Total')],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(scopeDetailsData.reduce((sum, row) => sum + (row.totalco2eq || 0), 0).toString() || '0')],
                                                columnSpan: 7
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: {after: 300},
                            }),
                            new Paragraph({
                                text: 'Footprint Doughnut Chart',
                                heading: "Heading1",
                            }),
                            footprintDoughnut ? new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: footprintDoughnut,
                                        transformation: {
                                            width: 150,  // Match these with the aspect ratio
                                            height: 200,
                                        },
                                    }),
                                ],
                            }) : new Paragraph({
                                text: 'No Doughnut Chart available.',
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: {after: 300},
                            }),
                            new Paragraph({
                                text: 'Footprint Bar Chart',
                                heading: "Heading1",
                            }),
                            footprintChart ? new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: footprintChart,
                                        transformation: {
                                            width: 600,  // Match these with the aspect ratio
                                            height: 300,
                                        },
                                    }),
                                ],
                            }) : new Paragraph({
                                text: 'No Bar Chart available.',
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: {after: 300},
                            }),
                            // GHG Data Section
                            new Paragraph({
                                text: 'GHG Emission Assessment',
                                heading: "Heading1",
                            }),

                            new Paragraph({
                                text: 'Organizational Boundaries',
                                heading: "Heading2",
                            }),

                            LCAData.organizationalBoundaries ? new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(organizationalBoundariesDescription)]}),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(LCAData.organizationalBoundaries)]}),
                                        ],
                                    }),
                                ],
                            }) : null,

                            new Paragraph({
                                text: 'Operational Boundaries',
                                heading: "Heading2",
                            }),

                            LCAData.operationalBoundariesScope3 ? new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(operationalBoundariesScope3)]}),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(LCAData.operationalBoundariesScope3)]}),
                                        ],
                                    }),
                                ],
                            }) : null,

                            LCAData.operationalBoundariesCO2 ? new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(operationalBoundariesCO2)]}),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(LCAData.operationalBoundariesCO2)]}),
                                        ],
                                    }),
                                ],
                            }) : null,

                            new Paragraph({
                                text: 'Base Year',
                                heading: "Heading2",
                            }),

                            LCAData.baseYearLine1 ? new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(baseYearLine1)]}),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(LCAData.baseYearLine1)]}),
                                        ],
                                    }),
                                ],
                            }) : null,

                            LCAData.baseYearLine2 ? new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(baseYearLine2)]}),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(LCAData.baseYearLine2)]}),
                                        ],
                                    }),
                                ],
                            }) : null,

                            LCAData.baseYearLine3 ? new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(baseYearLine3)]}),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({children: [new Paragraph(LCAData.baseYearLine3)]}),
                                        ],
                                    }),
                                ],
                            }) : null,

                            new Paragraph({
                                text: 'Methodology',
                                heading: "Heading2",
                            }),
                            LCAData.formattedEmissions ? new Paragraph({
                                text: `Methodology: ${LCAData.formattedEmissions}`,
                            }) : null,

                            new Paragraph({
                                text: 'References',
                                heading: "Heading2",
                            }),
                            LCAData.formattedReferences ? new Paragraph({
                                text: `References: ${LCAData.formattedReferences}`,
                            }) : null,
                        ],
                        footers: {
                            default: new Footer({
                                children: [
                                    new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: 'Arivu.2024 | Copyright © 2024 VRTTA Green Solutions. All rights reserved.',
                                                font: 'Arial',
                                                size: 20, // font size
                                                italics: true,
                                            })
                                        ],
                                        alignment: 'center', // Center-align the footer text
                                    })
                                ]
                            })
                        }
                    },
                ],
            });

            const blob = await Packer.toBlob(doc);
            saveAs(blob, 'Corporate_Report.docx');
        } catch (error) {
            console.error('Error generating DOCX:', error);
            alert('An error occurred while generating the DOCX file.');
        }
    };

    return (
        <div className='organizationalorganization'>
            <div className='table-div'>
                <Header selectedLanguage={selectedLanguage}/>
                <div className="main-table-container">
                    <TableComponent
                        isSupplier={false}
                        isOrganization={true}
                        setSelectedRowData={setSelectedRowData}
                        setMainTableGridApi={setMainTableGridApi}
                        setTrackViewState={setTrackView}
                        selectedLanguage={selectedLanguage}
                        setAllTableRows={setAllTableRows}
                        childTableRows={scopeData}
                        scopeTableTotal={scopeTableTotal}
                        pageType={'corporate'}
                    />
                </div>
                {!trackView && (
                    <>
                        <div className="process-env-container">
                            <ScopeTable
                                setSelectedScopeData={setSelectedScopeData}
                                setScopeData={setScopeData}
                                selectedRow={selectedRowData[0]}
                                setScopeTableTotal={setScopeTableTotal}
                                setScopeTableGridApi={setScopeTableGridApi}
                                pageType={'corporate'}
                                totalGWP={totalGWP}
                                selectedLanguage={selectedLanguage}
                            />
                            <ScopeCategoryDetailsTable
                                selectedScope={selectedScopeData[0]}
                                setCategoryDetailsTableGridApi={setCategoryDetailsTableGridApi}
                                setScopeDetailsData={setScopeDetailsData}
                                setSelectedEmissionDetailsData={setSelectedEmissionDetailsData}
                                setTotalGWP={setTotalGWP}
                                pageType={'corporate'}
                                selectedLanguage={selectedLanguage}
                            />
                        </div>
                        <div className='footprint-div'>
                            <ScopeFootprint scopeData={scopeData} scopeDetailsData={scopeDetailsData}
                                            selectedLanguage={selectedLanguage} setFootprintChart={setFootprintChart}
                                            setFootprintDoughnut={setFootprintDoughnut} selectedRow={selectedRowData[0]}
                                            selectedScopeData={selectedScopeData[0]}/>

                        </div>
                    </>
                )}
                <div className="life-cycle-assessment-div">
                    <OrganizationalBottomLCA selectedRow={selectedRowData[0]}
                                             selectedLanguage={selectedLanguage}
                                             setLCAData={setLCAData}/>
                </div>
                <div className="inventory-date-container-supplier">
                    <button onClick={exportAsDocx} className="generate-report-supplier-button">
                        <FiDownload size={18} style={{marginRight: '8px'}}/>
                        {selectedText.generateReport}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default OrganizationalCorporate;