import React, { useState, useEffect } from 'react';
import { Document, Packer, Paragraph, Table, TableCell, TableRow, TextRun, ImageRun, Footer } from 'docx';
import { saveAs } from 'file-saver';
import TableComponent from "../environment-page-components/Table";
import ProcessTable from "../environment-page-components/ProcessTable";
import EnvFactorTable from "../environment-page-components/EnvFactorTable";
import Footprint from "../environment-page-components/Footprint";
import SystemBoundary from "../environment-page-components/SystemBoundary";
import LifeCycleAssessment from "../environment-page-components/LifeCycleAssessment";
import '../style/pages-style/Supplier.css';
import Header from "../app_components/Header";
import { translations } from "../app_components/Translation";
import { FiDownload } from 'react-icons/fi';

function Supplier({ selectedLanguage }) {
    const pageType = 'supplier';
    const [selectedRowData, setSelectedRowData] = useState([]);  //selected rows of main table
    const [selectedProcessData, setSelectedProcessData] = useState([]);  //selected rows of process table
    const [selectedEnvFactorData, setSelectedEnvFactorData] = useState([]);  //selected rows of env factor table
    const [processData, setProcessData] = useState([]);  //All rows of process table
    const [mainTableGridApi, setMainTableGridApi] = useState(null);  //gridAPI of the main table
    const [processTableGridApi, setProcessTableGridApi] = useState(null);  //gridAPI of the process table
    const [envFactorTableGridApi, setEnvFactorTableGridApi] = useState(null);  //gridAPI of the env factor table
    const [trackView, setTrackView] = useState(false);  // State to track if track view is open
    const [allEnvFactorRows, setAllEnvFactorRows] = useState([]);  // All rows of env factor table
    const [allTableRows, setAllTableRows] = useState([]);  // All rows of main table
    const [allProcessRows, setAllProcessRows] = useState([]);  // All rows of process table

    const [footprintChart, setFootprintChart] = useState(null);  // Footprint image
    const [footprintDoughnut, setFootprintDoughnut] = useState(null);  // Footprint doughnut chart

    const [systemBoundaryTable, setSystemBoundaryTable] = useState(null);  // Data for system boundary

    const [lcaData, setLcaData] = useState([]);  // Data for life cycle assessment

    const selectedText = translations[selectedLanguage];

    console.log('lcadata:', lcaData);

    console.log('systemBoundaryTable:', systemBoundaryTable);

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(
        selectedLanguage === 'FR' ? 'fr-FR' : 'en-US',
        { year: 'numeric', month: 'long', day: 'numeric' }
    );

    // Function to create DOCX
    const exportAsDocx = async () => {
        if (selectedRowData.length !== 1) {
            alert('Please select exactly one product from the product table to generate the report.');
            return;
        }
        try {
            const doc = new Document({
                sections: [
                    {
                        children: [
                            new Paragraph({
                                text: 'Supplier Product Report',
                                heading: "Heading1",
                            }),
                            new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('Product')] }),
                                            new TableCell({ children: [new Paragraph('Supplier')] }),
                                            new TableCell({ children: [new Paragraph('LEED Category')] }),
                                            new TableCell({ children: [new Paragraph('Sub Category')] }),
                                            new TableCell({ children: [new Paragraph('Year')] }),
                                            new TableCell({ children: [new Paragraph('Quantity')] }),
                                            new TableCell({ children: [new Paragraph('GWP Total')] }),
                                            new TableCell({ children: [new Paragraph('Cost')] }),
                                            new TableCell({ children: [new Paragraph('Cost per GWP')] }),
                                        ],
                                    }),
                                    ...selectedRowData.map(row => new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph(row.product || '')] }),
                                            new TableCell({ children: [new Paragraph(row.supplier || '')] }),
                                            new TableCell({ children: [new Paragraph(row.leed_category || '')] }),
                                            new TableCell({ children: [new Paragraph(row.sub_category || '')] }),
                                            new TableCell({ children: [new Paragraph(row.Year?.toString() || '')] }),
                                            new TableCell({ children: [new Paragraph(row.quantity?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.gwp_total?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.cost?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.cost_per_gwp?.toString() || '0')] }),
                                        ],
                                    })),
                                ],
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 300 },
                            }),
                            new Paragraph({
                                text: 'Process Data',
                                heading: "Heading1",
                            }),
                            new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('LCA Stage')] }),
                                            new TableCell({ children: [new Paragraph('Name')] }),
                                            new TableCell({ children: [new Paragraph('GWP')] }),
                                        ],
                                    }),
                                    ...allProcessRows.map(row => new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph(row.lca_stage || '')] }),
                                            new TableCell({ children: [new Paragraph(row.name || '')] }),
                                            new TableCell({ children: [new Paragraph(row.gwp?.toString() || '0')] }),
                                        ],
                                    })),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph('Total')],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(allProcessRows.reduce((sum, row) => sum + (row.gwp || 0), 0).toString() || '0')],
                                                columnSpan: 2
                                            }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph('Biogenic Total')],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(allProcessRows.reduce((sum, row) => sum + (row.biogenic_gwp || 0), 0).toString() || '0')],
                                                columnSpan: 2
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 300 },
                            }),
                            new Paragraph({
                                text: 'Environment Factors',
                                heading: "Heading1",
                            }),
                            new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('Type')] }),
                                            new TableCell({ children: [new Paragraph('Category')] }),
                                            new TableCell({ children: [new Paragraph('Description')] }),
                                            new TableCell({ children: [new Paragraph('Unit')] }),
                                            new TableCell({ children: [new Paragraph('Quantity')] }),
                                            new TableCell({ children: [new Paragraph('Factor')] }),
                                            new TableCell({ children: [new Paragraph('GWP Unit')] }),
                                            new TableCell({ children: [new Paragraph('GWP Total')] }),
                                        ],
                                    }),
                                    ...allEnvFactorRows.map(row => new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph(row.type || '')] }),
                                            new TableCell({ children: [new Paragraph(row.category || '')] }),
                                            new TableCell({ children: [new Paragraph(row.description || '')] }),
                                            new TableCell({ children: [new Paragraph(row.unit || '')] }),
                                            new TableCell({ children: [new Paragraph(row.quantity?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.factor?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.gwp_unit?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(row.gwp_total?.toString() || '0')] }),
                                        ],
                                    })),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph('Total')],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(allEnvFactorRows.reduce((sum, row) => sum + (row.gwp_total || 0), 0).toString() || '0')],
                                                columnSpan: 2
                                            }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph('Biogenic Total')],
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(allProcessRows.reduce((sum, row) => sum + (row.biogenic_gwp || 0), 0).toString() || '0')],
                                                columnSpan: 2
                                            }),
                                        ],
                                    }),
                                ],
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 300 },
                            }),
                            new Paragraph({
                                text: 'Footprint Doughnut Chart',
                                heading: "Heading1",
                            }),
                            footprintDoughnut ? new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: footprintDoughnut,
                                        transformation: {
                                            width: 200,  // Match these with the aspect ratio
                                            height: 200,
                                        },
                                    }),
                                ],
                            }) : new Paragraph({
                                text: 'No Doughnut Chart available.',
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 300 },
                            }),
                            new Paragraph({
                                text: 'Footprint Bar Chart',
                                heading: "Heading1",
                            }),
                            footprintChart ? new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: footprintChart,
                                        transformation: {
                                            width: 600,  // Match these with the aspect ratio
                                            height: 300,
                                        },
                                    }),
                                ],
                            }) : new Paragraph({
                                text: 'No Bar Chart available.',
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 300 },
                            }),
                            new Paragraph({
                                text: 'System Boundary Table',
                                heading: "Heading1",
                            }),
                            systemBoundaryTable ? new Table({
                                rows: [
                                    // First row: Stage titles spanning multiple columns
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph("Product stage")],
                                                columnSpan: 3,
                                                verticalAlign: "center",
                                                shading: { fill: "DAE8FC" }, // Light blue
                                            }),
                                            new TableCell({
                                                children: [new Paragraph("Construction stage")],
                                                columnSpan: 2,
                                                verticalAlign: "center",
                                                shading: { fill: "DAE8FC" },
                                            }),
                                            new TableCell({
                                                children: [new Paragraph("Use stage")],
                                                columnSpan: 5,
                                                verticalAlign: "center",
                                                shading: { fill: "DAE8FC" },
                                            }),
                                            new TableCell({
                                                children: [new Paragraph("End-of-life stage")],
                                                columnSpan: 4,
                                                verticalAlign: "center",
                                                shading: { fill: "DAE8FC" },
                                            }),
                                            new TableCell({
                                                children: [new Paragraph("Beyond the building life cycle")],
                                                columnSpan: 1,
                                                verticalAlign: "center",
                                                shading: { fill: "DAE8FC" },
                                            }),
                                        ],
                                    }),
                                    // Second row: Process names under each stage
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph("A1: Raw Material Acquisition")], shading: { fill: "E1E8E3" } }),
                                            new TableCell({ children: [new Paragraph("A2: Transport to Factory")], shading: { fill: "E1E8E3" } }),
                                            new TableCell({ children: [new Paragraph("A3: Manufacturing")], shading: { fill: "E1E8E3" } }),
                                            new TableCell({ children: [new Paragraph("A4: Transport to Site")], shading: { fill: "DFF0D8" } }),
                                            new TableCell({ children: [new Paragraph("A5: Construction Installation")], shading: { fill: "DFF0D8" } }),
                                            new TableCell({ children: [new Paragraph("B1: Use")], shading: { fill: "F9FBE7" } }),
                                            new TableCell({ children: [new Paragraph("B2: Maintenance")], shading: { fill: "F9FBE7" } }),
                                            new TableCell({ children: [new Paragraph("B3: Repair")], shading: { fill: "F9FBE7" } }),
                                            new TableCell({ children: [new Paragraph("B4: Replacement")], shading: { fill: "F9FBE7" } }),
                                            new TableCell({ children: [new Paragraph("B5: Refurbishment")], shading: { fill: "F9FBE7" } }),
                                            new TableCell({ children: [new Paragraph("C1: Deconstruction/Demolition")], shading: { fill: "D9EAD3" } }),
                                            new TableCell({ children: [new Paragraph("C2: Transport to Waste/Disposal")], shading: { fill: "D9EAD3" } }),
                                            new TableCell({ children: [new Paragraph("C3: Waste Processing")], shading: { fill: "D9EAD3" } }),
                                            new TableCell({ children: [new Paragraph("C4: Disposal of Waste")], shading: { fill: "D9EAD3" } }),
                                            new TableCell({ children: [new Paragraph("D: Beyond the building life cycle")], shading: { fill: "F0F2F7" } }),
                                        ],
                                    }),
                                    // Third row: Data values corresponding to each process
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].A1?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].A2?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].A3?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].A4?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].A5?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].B1?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].B2?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].B3?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].B4?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].B5?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].C1?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].C2?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].C3?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].C4?.toString() || '0')] }),
                                            new TableCell({ children: [new Paragraph(systemBoundaryTable.data[0].D?.toString() || '0')] }),
                                        ],
                                    }),
                                ],
                            }) : new Paragraph({
                                text: 'No System Boundary Table data available.',
                            }),
                            new Paragraph({
                                text: '', // Empty paragraph for spacing
                                spacing: { after: 300 },
                            }),
                            // LCA Data Section
                            new Paragraph({
                                text: 'Life Cycle Assessment Data',
                                heading: "Heading1",
                            }),

                            // General Information Section
                            new Paragraph({
                                text: 'General Information',
                                heading: "Heading2",
                            }),
                            lcaData.generalInformation ? new Table({
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('Reference PCR')] }),
                                            new TableCell({ children: [new Paragraph(lcaData.generalInformation.ReferencePCR || 'N/A')] }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('Declared Unit')] }),
                                            new TableCell({ children: [new Paragraph(lcaData.generalInformation.DeclaredUnit || 'N/A')] }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('Scope')] }),
                                            new TableCell({ children: [new Paragraph(lcaData.generalInformation.Scope || 'N/A')] }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('LCA Software')] }),
                                            new TableCell({ children: [new Paragraph(lcaData.generalInformation.LCA_Software || 'N/A')] }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('LCI Database(s)')] }),
                                            new TableCell({ children: [new Paragraph(lcaData.generalInformation.LCI_Database || 'N/A')] }),
                                        ],
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({ children: [new Paragraph('LCIA Methodology')] }),
                                            new TableCell({ children: [new Paragraph(lcaData.generalInformation.LCIA_Methodology || 'N/A')] }),
                                        ],
                                    }),
                                ],
                            }) : new Paragraph({
                                text: 'No General Information available.',
                            }),

                            // Cut-Off Section
                            lcaData.cutOff ? new Paragraph({
                                text: 'Cut-Off',
                                heading: "Heading2",
                            }) : null,
                            lcaData.cutOff ? new Paragraph({
                                text: `Cut-Off: ${lcaData.cutOff}`,
                            }) : null,

                            // Allocation Procedure Section
                            lcaData.allocationProcedure ? new Paragraph({
                                text: 'Allocation Procedure',
                                heading: "Heading2",
                            }) : null,
                            lcaData.allocationProcedure ? new Paragraph({
                                text: `Allocation Procedure: ${lcaData.allocationProcedure}`,
                            }) : null,

                            // Primary Sources of LCI Data Section
                            lcaData.primarySources ? new Paragraph({
                                text: 'Primary Sources of LCI Data',
                                heading: "Heading2",
                            }) : null,
                            lcaData.primarySources ? new Paragraph({
                                text: `Primary Sources: ${lcaData.primarySources}`,
                            }) : null,

                            // Interpretation Section
                            lcaData.interpretation ? new Paragraph({
                                text: 'Interpretation',
                                heading: "Heading2",
                            }) : null,
                            lcaData.interpretation ? new Paragraph({
                                text: `Interpretation: ${lcaData.interpretation}`,
                            }) : null,

                            // References Section
                            lcaData.formattedReferences ? new Paragraph({
                                text: 'References',
                                heading: "Heading2",
                            }) : null,
                            lcaData.formattedReferences ? new Paragraph({
                                text: `References: ${lcaData.formattedReferences}`,
                            }) : null,
                        ],
                        footers: {
                            default: new Footer({
                                children: [
                                    new Paragraph({
                                        children: [
                                            new TextRun({
                                                text: 'Arivu.2024 | Copyright © 2024 VRTTA Green Solutions. All rights reserved.',
                                                font: 'Arial',
                                                size: 20, // font size
                                                italics: true,
                                            })
                                        ],
                                        alignment: 'center', // Center-align the footer text
                                    })
                                ]
                            })
                        }
                    }
                ]
            });

            const blob = await Packer.toBlob(doc);
            saveAs(blob, 'Supplier_Report.docx');
        } catch (error) {
            console.error('Error generating DOCX:', error);
            alert('An error occurred while generating the DOCX file.');
        }
    };

    useEffect(() => {
        if (selectedRowData.length === 0) {
            setProcessData([]);
            setSelectedProcessData([]);
            setAllProcessRows([]);
            setSelectedEnvFactorData([]);
            setAllEnvFactorRows([]);
            setProcessTableGridApi(null);
        }
        console.log('xxxselectedRowData:', selectedRowData);
    }, [selectedRowData]);


    return (
        <div className='supplier-utilizer'>
            <div className='table-div'>
                <Header selectedLanguage={selectedLanguage} />
                <div className="main-table-container">
                    <TableComponent
                        setSelectedRowData={setSelectedRowData}
                        setMainTableGridApi={setMainTableGridApi}
                        setTrackViewState={setTrackView}
                        selectedLanguage={selectedLanguage}
                        setAllTableRows={setAllTableRows}
                        childTableRows={allProcessRows}
                        pageType={pageType}
                    />
                </div>
                {!trackView && (
                    <>
                        <div className="process-env-container">
                            <ProcessTable
                                setSelectedProcessData={setSelectedProcessData}
                                setProcessData={setProcessData}
                                selectedRow={selectedRowData[0]}
                                setProcessTableGridApi={setProcessTableGridApi}
                                isSupplier={true}
                                selectedLanguage={selectedLanguage}
                                setAllProcessRows={setAllProcessRows}
                            />
                            <EnvFactorTable
                                setSelectedEnvFactorData={setSelectedEnvFactorData}
                                selectedProcess={selectedProcessData[0]}
                                allSelectedProcesses={selectedProcessData}
                                selectedRow={selectedRowData[0]}
                                setEnvFactorTableGridApi={setEnvFactorTableGridApi}
                                isSupplier={true}
                                selectedLanguage={selectedLanguage}
                                setAllEnvFactorRows={setAllEnvFactorRows}
                            />
                        </div>
                        <div className='footprint-div'>
                            <Footprint
                                processData={processData}
                                selectedLanguage={selectedLanguage}
                                setFootprintChart={setFootprintChart}
                                setFootprintDoughnut={setFootprintDoughnut}
                                pageType={pageType}
                            />
                        </div>
                        <div className="system-boundary-div">
                            <SystemBoundary
                                processData={processData}
                                selectedLanguage={selectedLanguage}
                                setSystemBoundaryTable={setSystemBoundaryTable}
                                isSupplier={true}
                            />
                        </div>
                    </>
                )}
                <div className="life-cycle-assessment-div">
                    <LifeCycleAssessment
                        selectedRow={selectedRowData[0]}
                        selectedLanguage={selectedLanguage}
                        setLcaData={setLcaData}
                    />
                </div>
                {/* Inventory Date and Export as DOCX Button Container */}
                <div className="inventory-date-container-supplier">
                    <button onClick={exportAsDocx} className="generate-report-supplier-button" >
                        <FiDownload size={18} style={{ marginRight: '8px' }} />
                        {selectedText.generateReport}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Supplier;
