import React, { memo, useEffect, useState } from "react";
import "../style/components-style/ProcessEnvFactorButton.css";
import config from "../config";
import { translations } from "../app_components/Translation";
import eventBus from "../eventBus";
import { Icon } from "@iconify/react"; // Import the eventBus

function ProcessEnvFactorButton({
  selectedRows,
  selectedProcessesRows = [],
  allSelectedProcesses = [],
  selectedEnvFactorRows = [],
  tableGridApi,
  isForProcess,
  product,
  design,
  process,
  allRows,
  refreshData,
  selectedLanguage,
  isEditable,
}) {
  const [disableSave, setDisableSave] = useState(true);
  const [isSaveDisabledState, setIsSaveDisabledState] = useState(true); // New state to track the disabled state of save button

  const selectedText = translations[selectedLanguage].processEnvFactorButton;

  const onCellValueChanged = (event) => {
    setDisableSave(false); // Enable the save button on cell change
    checkIfSaveDisabled(); // Check if the save button should be disabled
  };

  useEffect(() => {
    if (tableGridApi) {
      tableGridApi.addEventListener("cellValueChanged", onCellValueChanged);
    }

    return () => {
      if (tableGridApi) {
        tableGridApi.removeEventListener("cellValueChanged", onCellValueChanged);
      }
    };
  }, [tableGridApi]);

  const createNewProcessData = () => {
    if (product) {
      return {
        id: undefined,
        lca_stage: "",
        name: "",
        design_id: null,
        product_id: product["id"],
      };
    } else {
      return {
        id: undefined,
        lca_stage: "",
        name: "",
        design_id: design["id"],
        product_id: null,
      };
    }
  };

  const createNewEnvFactorData = () => {
    return {
      type: "",
      category: "",
      description: "",
      default_unit: null,
      unit: "",
      factor: 1,
      quantity: 0,
      gwp_total: 0,
      default_gwp: 0,
      gwp_unit: 0,
      process_id: process["id"],
    };
  };

  const addRow = () => {
    if (!tableGridApi || (!isForProcess && allSelectedProcesses.length !== 1))
      return;
    if (isForProcess) {
      tableGridApi.applyTransaction({ add: [createNewProcessData()] });
    } else {
      tableGridApi.applyTransaction({ add: [createNewEnvFactorData()] });
    }
    setDisableSave(false);
    checkIfSaveDisabled(); // Trigger check after adding a row
  };

  const deleteSelectedRow = () => {
    if (!tableGridApi || selectedProcessesRows.length === 0) return;
  
    const selectedIds = selectedProcessesRows.map((row) => row.id);
    const tableName = isForProcess ? "processes" : "env_factors";
  
    fetch(`${config.apiUrl}/delete_row`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ table_name: tableName, selected_ids: selectedIds }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete rows");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Deleted:", data);
        // Remove the rows from the table
        tableGridApi.applyTransaction({ remove: selectedProcessesRows });
        // Now save the table after confirming the deletion
        saveTable(); 
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  

  const saveTable = () => {
    const requestData = [];
    tableGridApi.forEachNode((node) => requestData.push(node.data));
    console.log("requestData:", requestData);

    const endpoint = isForProcess ? "save_processes" : "save_env_factors";
    fetch(`${config.apiUrl}/${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        refreshData(); // Refresh the data after saving
        eventBus.emit("dataSaved"); // Emit an event when the data is saved
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setDisableSave(true);
    setIsSaveDisabledState(true); // Reset the save button state after saving
  };

  // Function to check if the save button should be disabled
  const checkIfSaveDisabled = () => {
    const isDisabled = isSaveDisabled();
    setIsSaveDisabledState(isDisabled); // Update the state with the result of validation
  };

  // Validation function to check if any required fields are empty
  const isSaveDisabled = () => {
    // If it's for a process, we don't need to validate the fields
    if (isForProcess) {
      return false; // Save is not disabled when it's for a process
    }

    // Ensure the tableGridApi is available before proceeding
    if (!tableGridApi) {
      return true; // Disable the save button if tableGridApi is not ready
    }

    let isDisabled = false;

    tableGridApi.forEachNode((node) => {
      const { type, category, description, unit } = node.data;

      if (!type || !category || !description || !unit) {
        isDisabled = true; // Disable if any field is empty
      }
    });

    console.log("isSaveDisabled:", isDisabled);
    return isDisabled;
  };

  return (
    <div className="button-container">
      <div className="button-container-left">
        <button
          onClick={addRow}
          disabled={!isEditable || (!isForProcess && allSelectedProcesses.length !== 1)}
          title="Add a new row in the table"
        >
          <Icon icon="ic:round-plus" style={{ fontSize: "16px" }} />
        </button>

        <button
          onClick={deleteSelectedRow}
          disabled={selectedProcessesRows.length === 0 || !isEditable}
          title="Delete selected rows"
          className="delete-button"
        >
          <Icon icon="ic:round-minus" style={{ fontSize: "16px" }} />
        </button>
      </div>
      <div className="button-container-right">
        <button
          onClick={saveTable}
          disabled={!isEditable || disableSave || isSaveDisabledState}
          title="Save current data in the table to database"
        >
          <Icon icon="mdi:floppy-disk" style={{ fontSize: "16px" }} />
        </button>
      </div>
    </div>
  );
}

export default memo(ProcessEnvFactorButton);
