import React, {useState} from 'react';
import {Handle, Position} from 'react-flow-renderer';
import '../style/components-style/ClickableNode.css'
import {Icon} from "@iconify/react";

const ClickableNode = ({data}) => {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle the list when the node is clicked
    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="custom-node">
            <div className="node-label-container">
                {/* Node Label */}
                <div className="node-label">{data.label}</div>

                {/* Plus Icon */}
                <div className="plus-icon" onClick={handleClick}>
                    <Icon icon="ei:plus" />
                </div>
            </div>

            {/* Show the list when the plus icon is clicked */}
            {isOpen && (
                <div className="node-list">
                    <ul>
                        {data.list.map((item, index) => (
                            <li key={index}>{index + 1}.{item}</li>
                        ))}
                    </ul>
                </div>
            )}

            {/* Handles for connecting edges */}
            <Handle type="source" position={Position.Bottom}/>
            <Handle type="target" position={Position.Top}/>
        </div>
    );
};

export default ClickableNode;