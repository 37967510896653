import React, { useState, useEffect } from "react";
import Grid from "../../environment-page-components/Grid";
import styles from "./AssetsTable.module.css";
import config from "../../config";
import { Icon } from "@iconify/react";

const AssetsTable = ({ selectedRowData, selectedLanguage, refreshData }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedRow, setSelectedRow] = useState(selectedRowData);
  const [selectedAssetNumbers, setSelectedAssetNumbers] = useState([]);

  const [allAssets, setAllAssets] = useState([]);

  const apiKey = "samsara_api_v4yzCt6mQpGraWC9sfXgu4Fs3dTi2M";
  const startDate = "2023-01-01";
  const endDate = "2023-12-31";
  const system = "metric";

  const toggleContainer = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (selectedRowData.length > 0) {
      setSelectedAssetNumbers(selectedRowData[0].asset_numbers || []);
    }
  }, [selectedRowData]);

  // Fetch data only once on component mount
  useEffect(() => {
    const fetchTableData = async () => {
      setLoadingData(true);
      try {
        const urlBase = "https://gaddi-js-api.vrtta.green";
        const response = await fetch(`${urlBase}/samsara-proxy?apiKey=${apiKey}&startDate=${startDate}&endDate=${endDate}&system=${system}`);
        if (!response.ok) throw new Error("Network response was not ok");

        const data = await response.json();
        if (!(Array.isArray(data[0]) && data[0].length > 0)) {
          localStorage.clear();
          throw new Error("No Data Found!");
        }

        const mappedData = data[0].map((item) => ({
          assetNo: item["Vehicle"],
          fuelEconomy: item["Fuel economy (L/100km)"],
          fuelConsumed: item["Fuel consumption (L)"],
          distanceTravelled: item["Distance travelled (km)"],
          idlingPercent: item["Idling time (%)"],
          ghgEmissions: (item["Fuel consumption (L)"] * 2.7).toFixed(2),
        }));

        setAllAssets(mappedData); // Store all assets
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingData(false);
      }
    };

    fetchTableData();
  }, []);

  // Update tableData based on selectedAssetNumbers
  useEffect(() => {
    const filteredData = allAssets.filter((asset) => selectedAssetNumbers.includes(asset.assetNo));
    setTableData(filteredData);
  }, [selectedAssetNumbers, allAssets]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleAssetSelection = (assetNo) => {
    setSelectedAssetNumbers((prevSelected) => {
      if (prevSelected.includes(assetNo)) {
        return prevSelected.filter((item) => item !== assetNo);
      } else {
        return [...prevSelected, assetNo];
      }
    });
  };

  const saveTable = async () => {
    const selectedAssetsData = tableData.filter((row) => selectedAssetNumbers.includes(row.assetNo));

    // Ensure selectedAssetsData is not empty before trying to use it
    if (selectedAssetsData.length === 0) {
      console.error("No assets selected");
      return;
    }

    const requestData = selectedRow.map((row) => ({
      fleet: row.fleet,
      fleet_type: row.fleet_type,
      location: row.location,
      year: row.year,
      fuel_consumed: selectedAssetsData[0]?.fuelConsumed || 0,
      distance_travelled: selectedAssetsData[0]?.distanceTravelled || 0,
      gwp_total: selectedAssetsData[0]?.ghgEmissions || 0,
      distance_per_gwp: selectedAssetsData[0]?.distanceTravelled / selectedAssetsData[0]?.fuelConsumed || 0,
      asset_numbers: JSON.stringify(selectedAssetNumbers),
      user_id: row.user_id,
    }));

    try {
      for (let i = 0; i < selectedRow.length; i++) {
        const response = await fetch(`${config.apiUrl}/update_data`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            table_name: "fleets",
            data: requestData[i],
            condition: `id = '${selectedRow[i].id}'`,
          }),
        });

        if (!response.ok) {
          console.error("Failed to update fleet metrics");
        }
      }
      // Make sure refreshData is called after all requests are successful
      refreshData();
    } catch (error) {
      console.error("Error saving fleet metrics:", error);
    }
  };

  const assetsGridProps = {
    columnDefs: [
      { headerName: "Asset No", field: "assetNo", editable: true, minWidth: 100, checkboxSelection: true, headerCheckboxSelection: true },
      { headerName: "Fuel Economy (L/100km)", field: "fuelEconomy", editable: true, minWidth: 150 },
      {
        headerName: "Fuel Consumed (L)",
        field: "fuelConsumed",
        editable: true,
        minWidth: 150,
        valueFormatter: (params) => Math.round(params.value).toString(),
      },
      {
        headerName: "Distance Travelled (km)",
        field: "distanceTravelled",
        editable: true,
        minWidth: 160,
        valueFormatter: (params) => Math.round(params.value).toString(),
      },
      { headerName: "Idling %", field: "idlingPercent", editable: true, minWidth: 120 },
      { headerName: "GHG Emissions (kg CO2e)", field: "ghgEmissions", editable: true, minWidth: 200, cellStyle: { textAlign: "right" } },
    ],
    rowData: tableData,
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
      defaultToolPanel: "filters",
    },
    onGridReady: onGridReady,
  };

  return (
    <div className="process-table">
      <div className="container-header">
        <h2
          onClick={toggleContainer}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            cursor: "pointer",
            color: isHovered ? "grey" : "black",
          }}
        >
          Assets
        </h2>
      </div>
      {loadingData ? (
        <div className={styles.loadingSpinner}>Loading...</div>
      ) : isOpen ? (
        <>
          <div className={styles.gridContainerEnv}>
            <div className="grid-container">
              <Grid {...assetsGridProps} customMinHeight={264} customMaxheight={264} />
              <div className="gwp-total-env">
                <span className="gwp-total-env-text">Total:</span>
                <span className="gwp-total-env-number"></span>
              </div>
            </div>
            <div className={styles.assetSelectionContainer}>
              <h3 className={styles.assetSelectionTitle}>Asset Selection</h3>
              <div className={styles.availableAssets}>
                {allAssets.map((asset) => (
                  <div key={asset.assetNo} className={`${styles.assetItem} ${selectedAssetNumbers.includes(asset.assetNo) ? styles.assetItemSelected : ""}`} onClick={() => handleAssetSelection(asset.assetNo)}>
                    {asset.assetNo}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="button-container-env">
            <div className="button-container">
              <div className="button-container-left"></div>
              <div className="button-container-right">
                <button onClick={saveTable} title="Save current data in the table to database">
                  <Icon icon="mdi:floppy-disk" style={{ fontSize: "16px" }} />
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AssetsTable;
