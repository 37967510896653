import React from 'react';
import '../style/pages-style/NetZero.css';
import Header from '../app_components/Header';

function NetZero({ selectedLanguage }) {
  return (
    <div className="netzero-page">
      <Header selectedLanguage={selectedLanguage} />
      <div className="netzero-content">
        <h1>Net Zero</h1>
      </div>
    </div>
  );
}

export default NetZero;
