// HomePage.js
import React from 'react';
import supplierIcon from '@iconify-icons/mdi/account-supervisor-circle';
import utilizerIcon from '@iconify-icons/mdi/group';
import leafCircleOutlineIcon from '@iconify-icons/mdi/leaf-circle-outline';
import comparisonIcon from '@iconify-icons/mdi/scale-balance';
import HomePageTab from '../homepage-components/HomePageTab';
import '../style/pages-style/HomePage.css';
import Header from '../app_components/Header';
import { translations } from '../app_components/Translation';

function HomePage({ selectedLanguage }) {
  const selectedText = translations[selectedLanguage];

  const features = [
    {
      icon: supplierIcon,
      title: selectedText.supplier,
      description: selectedText.description,
      link: '/supplier'
    },
    {
      icon: utilizerIcon,
      title: selectedText.utilizer,
      description: selectedText.description,
      link: '/utilizer'
    },
    {
      icon: comparisonIcon,
      title: selectedText.comparisonTitle,
      description: selectedText.description,
      link: '/comparison'
    },
    {
      icon: "ph:recycle",
      title: selectedText.reuseTitle,
      description: selectedText.description,
      link: '/reuse'
    },
    {
      icon: leafCircleOutlineIcon, 
      title: selectedText.circularityTitle, 
      description: selectedText.description,
      link: '/circularity'
    }
  ];

  return (
    <div className="home-page">
      <Header selectedLanguage={selectedLanguage}/>
      {features.map((feature, index) => (
        <HomePageTab
          key={index}
          icon={feature.icon}
          title={feature.title}
          description={feature.description}
          link={feature.link}
        />
      ))}
    </div>
  );
}

export default HomePage;
