import React, { useState, useEffect } from 'react';
import '../style/pages-style/Circularity.css';
import Header from '../app_components/Header';
import TableComponent from "../environment-page-components/Table";
import '../style/components-style/Table.css';
import config from '../config';
import Grid from '../environment-page-components/Grid';
import { translations } from "../app_components/Translation";

function Circularity({ selectedLanguage }) {
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [mainTableGridApi, setMainTableGridApi] = useState(null);
  const [trackViewState, setTrackViewState] = useState(false);
  const [allTableRows, setAllTableRows] = useState([]);
  const [pageType, setPageType] = useState('supplier');
  const [envFactors, setEnvFactors] = useState([]);
  const [isOpen, setIsOpen] = useState(true); 
  const circularityPage = true;

  const selectedText = translations[selectedLanguage].circularityPage;

  useEffect(() => {
    const fetchEnvFactorsForSelectedRows = async () => {
      let newEnvFactors = [];
      for (const row of selectedRowData) {
        const processIds = await fetchProcessIdsByRowId(row.id);
        const filteredProcessIds = processIds.filter(process => process.lca_stage && process.lca_stage.startsWith('A1'));
        for (const process of filteredProcessIds) {
          const factors = await fetchEnvFactorsByProcessId(process.id);
          console.log(factors); // Debugging line
          if (Array.isArray(factors)) { // Ensure factors is iterable
            newEnvFactors = [...newEnvFactors, ...factors];
          } else {
            console.warn('Factors is not an array', factors);
          }
        }
      }
      setEnvFactors(newEnvFactors);
    };
    
    if (selectedRowData.length > 0) {
      fetchEnvFactorsForSelectedRows();
    } else {
      setEnvFactors([]);  // Clear envFactors if no rows are selected
    }

  }, [selectedRowData]);

  const fetchProcessIdsByRowId = async (rowId) => {
    try {
      const response = await fetch(`${config.apiUrl}/api/get-process-ids-by-row-id?row_id=${rowId}`);
      return await response.json();
    } catch (error) {
      console.error('Error fetching process IDs:', error);
      return [];
    }
  };

  const fetchEnvFactorsByProcessId = async (processId) => {
    try {
      const response = await fetch(`${config.apiUrl}/api/get-env-factor-by-process-id?process_id=${processId}`);
      const data = await response.json();
      
      // Check if data is an array; if not, return an empty array
      if (Array.isArray(data)) {
        return data;
      } else {
        console.warn('Factors is not an array', data);
        return [];  // Default to an empty array if data is not iterable
      }
    } catch (error) {
      console.error('Error fetching environmental factors:', error);
      return []; // Return empty array on error
    }
  };
  
  const toggleContainer = () => setIsOpen(!isOpen);

  const columnDefs = [
    { headerName: selectedText.type, field: 'type', sortable: true, filter: true, checkboxSelection: true, headerCheckboxSelection: true },
    { headerName: selectedText.category, field: 'category', sortable: true, filter: true },
    { headerName: selectedText.description, field: 'description', sortable: true, filter: true },
    { headerName: selectedText.unit, field: 'unit', sortable: true, filter: true },
    { headerName: selectedText.quantity, field: 'quantity', sortable: true, filter: true },
    { headerName: selectedText.factor, field: 'factor', sortable: true, filter: true },
    { headerName: selectedText.gwpUnit, field: 'gwp_unit', sortable: true, filter: true },
    { headerName: selectedText.gwpTotal, field: 'gwp_total', sortable: true, filter: true }
  ];

  return (
    <div className="supplier-utilizer">
      <div className="circularity-page">
        <Header selectedLanguage={selectedLanguage} />
        <div className={`main-table-container-circularity ${circularityPage ? 'circularity-active' : ''}`}>
          <TableComponent
            setSelectedRowData={setSelectedRowData}
            setMainTableGridApi={setMainTableGridApi}
            setTrackViewState={setTrackViewState}
            selectedLanguage={selectedLanguage}
            setAllTableRows={setAllTableRows}
            childTableRows={[]}
            pageType={pageType}
            setPageType={setPageType}
            circularityPage={circularityPage}
          />
          
          <div className="material-container">
            <div className="container-header-material">
              <h2 
                onClick={toggleContainer} 
                onMouseEnter={() => document.querySelector('.container-header-material h2').style.color = 'grey'}  // Hover color
                onMouseLeave={() => document.querySelector('.container-header-material h2').style.color = 'black'} // Default color
                style={{ cursor: 'pointer' }}
              >
                {selectedText.materials}
              </h2>
            </div>
            
            {isOpen && (
              <Grid
                rowData={envFactors}
                columnDefs={columnDefs}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Circularity;
