import React from 'react';
import "../style/components-style/PhaseCard.css";
import { translations } from '../app_components/Translation';

function PhaseCard({ phase, current, average, isLess, selectedLanguage }) {
    const selectedText = translations[selectedLanguage]?.phaseCard || {};

    // Fallback to 0 if the value is NaN or undefined
    const validCurrent = isNaN(current) ? 0 : current;
    const validAverage = isNaN(average) ? 0 : average;

    const indicatorStyle = {
        color: isLess ? '#31D411' : '#C43838',
        display: 'inline-block',
        transform: isLess ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.3s ease'
    };

    const difference = Math.abs(validCurrent - validAverage).toFixed(2);
    const displayIndicator = validCurrent !== 0 ? <span style={indicatorStyle}>▲</span> : <span>-</span>;

    return (
        <div className="phase-card">
            <div className="phase-title">{phase}</div>
            <div className="current-value">{validCurrent} kg CO₂e</div>
            <div className="comparison">
                {displayIndicator}
                <span>{difference}</span>
            </div>
            <div className="average-value">{selectedText.average || 'Average'}: {validAverage} kg CO₂e</div>
        </div>
    );
}

export default PhaseCard;
