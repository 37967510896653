import React from "react";
import environmentIcon from "@iconify-icons/mdi/environment";
import organizationIcon from "@iconify-icons/mdi/human-queue";
import zeroBoxIcon from "@iconify-icons/mdi/numeric-zero-box-multiple-outline";
import leafCircleOutlineIcon from "@iconify-icons/mdi/leaf-circle-outline";
import truckIcon from "@iconify-icons/mdi/truck";
import HomePageTab from "../homepage-components/HomePageTab";
import "../style/pages-style/HomePage.css";
import Header from "../app_components/Header";
import { translations } from "../app_components/Translation";

function HomeViewType({ selectedLanguage }) {
  const selectedText = translations[selectedLanguage];

  const features = [
    {
      icon: environmentIcon,
      title: selectedText.product,
      description: selectedText.description,
      link: "/home",
    },
    {
      icon: truckIcon,
      title: selectedText.fleetPerformanceTitle,
      description: selectedText.description,
      link: "/fleet/perfomance",
    },
    {
      icon: organizationIcon,
      title: selectedText.organizational,
      description: selectedText.description,
      link: "/homeorganizational",
    },
    {
      icon: zeroBoxIcon,
      title: selectedText.netZeroTitle,
      description: selectedText.description,
      link: "/netzero",
    },
    {
      icon: leafCircleOutlineIcon,
      title: selectedText.circularityTitle,
      description: selectedText.description,
      link: "/circularity",
    },
  ];

  return (
    <div className="home-page">
      <Header selectedLanguage={selectedLanguage} />
      {features.map((feature, index) => (
        <HomePageTab key={index} icon={feature.icon} title={feature.title} description={feature.description} link={feature.link} />
      ))}
    </div>
  );
}

export default HomeViewType;
