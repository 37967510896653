import React, { useState } from "react";
import Header from "../app_components/Header";
import TableComponent from "../environment-page-components/Table";
import "../style/organizational-pages-style/organizationalorganization.css";
import { FiDownload } from "react-icons/fi";
import { translations } from "../app_components/Translation";
import AssetsTable from "./fleet-pages-components/AssetsTable";

function FleetPerformance({ selectedLanguage }) {
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [mainTableGridApi, setMainTableGridApi] = useState(null);
  const [trackView, setTrackView] = useState(false);
  const [allTableRows, setAllTableRows] = useState([]);

  const refreshData = () => {
    mainTableGridApi.refreshCells();
  };

  const selectedText = translations[selectedLanguage];

  const handleAssetDataUpdate = (updatedData) => {
    setSelectedRowData((prevData) => ({ ...prevData, ...updatedData }));
    setAllTableRows((prevRows) => prevRows.map((row) => (row.id === selectedRowData.id ? { ...row, ...updatedData } : row)));
  };

  return (
    <div className="organizationalorganization">
      <div className="table-div">
        <Header selectedLanguage={selectedLanguage} />
        <div className="main-table-container">
          <TableComponent setSelectedRowData={setSelectedRowData} setMainTableGridApi={setMainTableGridApi} setTrackViewState={setTrackView} selectedLanguage={selectedLanguage} setAllTableRows={setAllTableRows} childTableRows={selectedRowData} pageType={"fleet"} />
        </div>
        {!trackView && (
          <div className="process-env-container">
            <AssetsTable selectedRowData={selectedRowData} selectedLanguage={selectedLanguage} refreshData={refreshData} onAssetDataUpdate={handleAssetDataUpdate} />
          </div>
        )}
        <div className="inventory-date-container-supplier">
          <button className="generate-report-supplier-button">
            <FiDownload size={18} style={{ marginRight: "8px" }} />
            {selectedText.generateReport}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FleetPerformance;
