import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactFlow, {useEdgesState, useNodesState, useReactFlow, ReactFlowProvider} from 'react-flow-renderer';
import config from "../config";
import ClickableNode from "./ClickableNode";
import "../style/components-style/StructuralView.css"

const nodeTypes = {
    clickable: ClickableNode,  // Register the custom node type
};

function StructuralView({pageType, selectedRow}) {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const {setViewport} = useReactFlow();
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(1000);

    useEffect(() => {
        // Get the width of the container after it renders
        if (containerRef.current) {
            const width = containerRef.current.clientWidth;
            setContainerWidth(width);
        }
    }, []);

    const fetchOrganizationsAndValueChains = async (corporateId) => {
        try {
            const response = await fetch(`${config.apiUrl}/api/fetch-organization-and-value-chain-emissions-by-corporate-id?corporateId=${corporateId}`)
            return await response.json(); // Assuming the response contains organizations and valueChains
        } catch (error) {
            console.error('Error fetching organizations and value chains:', error);
            return {organizations: [], orgEmissions: {}, valueChainEmissions: {}, otherEmissions: []};
        }
    };

    const fetchValueChains = async (orgId) => {
        try {
            const response = await fetch(`${config.apiUrl}/api/fetch-value-chain-emissions-by-org-id?orgId=${orgId}`)
            return await response.json(); // Assuming the response contains organizations and valueChains
        } catch (error) {
            console.error('Error fetching organizations and value chains:', error);
            return {valueChainEmissions: {}, otherEmissions: []};
        }
    }

    const fetchValueChainEmissions = async (productServiceId) => {
        try {
            const response = await fetch(`${config.apiUrl}/api/fetch-emissions-by-value-chain-id?productServiceId=${productServiceId}`)
            return await response.json(); // Assuming the response contains organizations and valueChains
        } catch (error) {
            console.error('Error fetching organizations and value chains:', error);
            return {Emissions: []};
        }
    }

    // Function to generate new nodes and edges based on the data
    const createCorporateNodesAndEdges = (corporate, organizations, orgEmissions, valueChainEmissions, otherEmissions) => {
        const newNodes = [];
        const newEdges = [];

        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;

        const orgYPosition = 150;
        const valueChainYPosition = 300;
        const valueChainEmissionsYPosition = 450;
        const orgXSpacing = 400;
        const chainXSpacing = 200;

        const corporateNode = corporate
            ? {
                id: 'corporate',
                type: 'default',
                data: {label: corporate, list: organizations, visible: true, childIds: []},
                position: {x: 250, y: 50}
            }
            : null;
        if (corporate) {
            // Update min/max x and y
            minX = Math.min(minX, corporateNode.position.x);
            maxX = Math.max(maxX, corporateNode.position.x);
            minY = Math.min(minY, corporateNode.position.y);
            maxY = Math.max(maxY, corporateNode.position.y);
            newNodes.push(corporateNode);
        }

        // Create nodes for organizations and their value chains
        if (organizations) {
            organizations.forEach((org, index) => {
                const orgId = `org-${index}`;
                const orgXPosition = index * orgXSpacing + 100;

                // Create organization node
                const orgNode = {
                    id: orgId,
                    type: 'default',
                    data: {label: org, visible: true, childIds: []},
                    position: {x: orgXPosition, y: orgYPosition},
                };
                newNodes.push(orgNode);
                newEdges.push({id: `e-corporate-${orgId}`, source: 'corporate', target: orgId});
                corporateNode.data.childIds.push(orgId);

                // Update min/max x and y for the organization nodes
                minX = Math.min(minX, orgNode.position.x);
                maxX = Math.max(maxX, orgNode.position.x);
                minY = Math.min(minY, orgNode.position.y);
                maxY = Math.max(maxY, orgNode.position.y);

                // Create value chain nodes for each organization
                if (orgEmissions[org]) {
                    orgEmissions[org]['valueChains'].forEach((chain, chainIndex) => {
                        const chainId = `valueChain-${orgId}-${chainIndex}`;
                        const chainXPosition = orgXPosition + chainIndex * chainXSpacing;

                        // Create value chain node
                        const chainNode = {
                            id: chainId,
                            type: 'default',
                            data: {label: chain, visible: true, childIds: []},
                            position: {x: chainXPosition, y: valueChainYPosition},
                        };
                        newNodes.push(chainNode);
                        newEdges.push({id: `e-${orgId}-${chainId}`, source: orgId, target: chainId});
                        orgNode.data.childIds.push(chainId);

                        // Update min/max x and y for the value chain nodes
                        minX = Math.min(minX, chainNode.position.x);
                        maxX = Math.max(maxX, chainNode.position.x);
                        minY = Math.min(minY, chainNode.position.y);
                        maxY = Math.max(maxY, chainNode.position.y);

                        if (valueChainEmissions[chain]) {
                            const valueChainEmissionsNode = {
                                id: `${chain}-emissions`,
                                type: 'clickable',
                                data: {
                                    label: 'Emissions',
                                    list: valueChainEmissions[chain],
                                    visible: true
                                },
                                position: {x: chainXPosition, y: valueChainEmissionsYPosition},
                            }
                            minX = Math.min(minX, valueChainEmissionsNode.position.x);
                            maxX = Math.max(maxX, valueChainEmissionsNode.position.x);
                            minY = Math.min(minY, valueChainEmissionsNode.position.y);
                            maxY = Math.max(maxY, valueChainEmissionsNode.position.y);
                            newNodes.push(valueChainEmissionsNode)
                            newEdges.push({
                                id: `e-${chainId}-Emissions`,
                                source: chainId,
                                target: `${chain}-emissions`
                            });
                            chainNode.data.childIds.push(`${chain}-emissions`);
                        }
                    });

                    const orgEmissionsNode = {
                        id: `${org}-emissions`,
                        type: 'clickable',
                        data: {
                            label: 'Other Emissions',
                            list: orgEmissions[org]['otherEmissions'],
                            visible: true
                        },
                        position: {
                            x: orgXPosition + orgEmissions[org]['valueChains'].length * chainXSpacing + 100,
                            y: valueChainYPosition
                        },
                    }
                    minX = Math.min(minX, orgEmissionsNode.position.x);
                    maxX = Math.max(maxX, orgEmissionsNode.position.x);
                    minY = Math.min(minY, orgEmissionsNode.position.y);
                    maxY = Math.max(maxY, orgEmissionsNode.position.y);
                    newNodes.push(orgEmissionsNode)
                    newEdges.push({
                        id: `e-${orgId}-Emissions`,
                        source: orgId,
                        target: `${org}-emissions`
                    });
                    orgNode.data.childIds.push(`${org}-emissions`);
                }
            });
        }

        const otherEmissionsNode = {
            id: 'otherEmissions',
            type: 'clickable',
            data: {
                label: 'Other Emissions',
                list: otherEmissions,
                visible: true
            },
            position: {x: organizations.length * orgXSpacing + 100, y: orgYPosition},
        };
        minX = Math.min(minX, otherEmissionsNode.position.x);
        maxX = Math.max(maxX, otherEmissionsNode.position.x);
        minY = Math.min(minY, otherEmissionsNode.position.y);
        maxY = Math.max(maxY, otherEmissionsNode.position.y);
        newNodes.push(otherEmissionsNode)
        newEdges.push({id: `e-corporate-otherEmissions`, source: 'corporate', target: 'otherEmissions'});
        corporateNode.data.childIds.push('otherEmissions');

        // Calculate the center of the bounding box
        const centerX = (minX + maxX) / 2;
        const centerY = (minY + maxY) / 2;

        console.log(`Center of diagram (bounding box): (${centerX}, ${centerY})`);

        return {newNodes, newEdges, center: {x: centerX, y: centerY}};
    };

    const createOrgNodesAndEdges = (organization, valueChainsEmissions, otherEmissions) => {
        const newNodes = [];
        const newEdges = [];

        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;

        const valueChainYPosition = 150;
        const valueChainEmissionsYPosition = 300;
        const chainXSpacing = 200;

        const orgNode = organization
            ? {
                id: 'organization',
                type: 'default',
                data: {label: organization, visible: true, childIds: []},
                position: {x: 250, y: 50}
            }
            : null;
        if (organization) {
            // Update min/max x and y
            minX = Math.min(minX, orgNode.position.x);
            maxX = Math.max(maxX, orgNode.position.x);
            minY = Math.min(minY, orgNode.position.y);
            maxY = Math.max(maxY, orgNode.position.y);
            newNodes.push(orgNode);
        }

        // Create nodes for organizations and their value chains
        if (valueChainsEmissions) {
            Object.entries(valueChainsEmissions).forEach(([vc, emissions], index) => {
                const valueChainId = `vc-${index}`;
                const valueChainXPosition = index * chainXSpacing + 100;

                // Create organization node
                const valueChainNode = {
                    id: valueChainId,
                    type: 'default',
                    data: {label: vc, visible: true, childIds: []},
                    position: {x: valueChainXPosition, y: valueChainYPosition},
                };

                newNodes.push(valueChainNode);
                newEdges.push({id: `e-organization-${valueChainId}`, source: 'organization', target: valueChainId});
                orgNode.data.childIds.push(valueChainId);

                // Update min/max x and y for the organization nodes
                minX = Math.min(minX, valueChainNode.position.x);
                maxX = Math.max(maxX, valueChainNode.position.x);
                minY = Math.min(minY, valueChainNode.position.y);
                maxY = Math.max(maxY, valueChainNode.position.y);

                const valueChainEmissionsNode = {
                    id: `${vc}-emissions`,
                    type: 'clickable',
                    data: {
                        label: 'Emissions',
                        list: emissions,
                        visible: true
                    },
                    position: {
                        x: valueChainXPosition,
                        y: valueChainEmissionsYPosition
                    },
                }
                minX = Math.min(minX, valueChainEmissionsNode.position.x);
                maxX = Math.max(maxX, valueChainEmissionsNode.position.x);
                minY = Math.min(minY, valueChainEmissionsNode.position.y);
                maxY = Math.max(maxY, valueChainEmissionsNode.position.y);
                newNodes.push(valueChainEmissionsNode)
                newEdges.push({
                    id: `e-${valueChainId}-Emissions`,
                    source: valueChainId,
                    target: `${vc}-emissions`
                });
                valueChainNode.data.childIds.push(`${vc}-emissions`);
            });
        }

        const otherEmissionsNode = {
            id: 'otherEmissions',
            type: 'clickable',
            data: {
                label: 'Other Emissions',
                list: otherEmissions,
                visible: true
            },
            position: {x: Object.entries(valueChainsEmissions).length * chainXSpacing + 100, y: valueChainYPosition},
        };
        minX = Math.min(minX, otherEmissionsNode.position.x);
        maxX = Math.max(maxX, otherEmissionsNode.position.x);
        minY = Math.min(minY, otherEmissionsNode.position.y);
        maxY = Math.max(maxY, otherEmissionsNode.position.y);
        newNodes.push(otherEmissionsNode)
        newEdges.push({id: `e-organization-otherEmissions`, source: 'organization', target: 'otherEmissions'});
        orgNode.data.childIds.push('otherEmissions');

        // Calculate the center of the bounding box
        const centerX = (minX + maxX) / 2;
        const centerY = (minY + maxY) / 2;

        console.log(`Center of diagram (bounding box): (${centerX}, ${centerY})`);

        return {newNodes, newEdges, center: {x: centerX, y: centerY}};
    };

    const createValueChainNodesAndEdges = (valueChain, emissions) => {
        const newNodes = [];
        const newEdges = [];

        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;

        const vcNode = valueChain
            ? {
                id: 'valueChain',
                type: 'default',
                data: {label: valueChain, visible: true, childIds: []},
                position: {x: 250, y: 50}
            }
            : null;
        if (valueChain) {
            // Update min/max x and y
            minX = Math.min(minX, vcNode.position.x);
            maxX = Math.max(maxX, vcNode.position.x);
            minY = Math.min(minY, vcNode.position.y);
            maxY = Math.max(maxY, vcNode.position.y);
            newNodes.push(vcNode);
        }

        // Create nodes for organizations and their value chains
        if (emissions) {
            const emissionsNode = {
                id: 'emissions',
                type: 'clickable',
                data: {
                    label: 'Emissions',
                    list: emissions,
                    visible: true
                },
                position: {x: 250, y: 200},
            };
            minX = Math.min(minX, emissionsNode.position.x);
            maxX = Math.max(maxX, emissionsNode.position.x);
            minY = Math.min(minY, emissionsNode.position.y);
            maxY = Math.max(maxY, emissionsNode.position.y);
            newNodes.push(emissionsNode)
            newEdges.push({id: `e-vc-Emissions`, source: 'valueChain', target: 'emissions'});
            vcNode.data.childIds.push('emissions');
        }

        // Calculate the center of the bounding box
        const centerX = (minX + maxX) / 2;
        const centerY = (minY + maxY) / 2;

        console.log(`Center of diagram (bounding box): (${centerX}, ${centerY})`);

        return {newNodes, newEdges, center: {x: centerX, y: centerY}};
    };

    const onNodeClick = useCallback(
        (event, node) => {
            if (node.type !== 'default') return;

            // Create a map for quick access to nodes by ID
            const nodeMap = new Map(nodes.map((n) => [n.id, n]));

            // Recursive function to gather nodes for visibility toggle
            const gatherChildNodesToToggle = (nodeId, isVisible, nodesToUpdate) => {
                const currentNode = nodeMap.get(nodeId);
                if (currentNode && currentNode.data.childIds) {
                    currentNode.data.childIds.forEach((childId) => {
                        const childNode = nodeMap.get(childId);
                        if (childNode) {
                            nodesToUpdate.push({
                                ...childNode,
                                data: {
                                    ...childNode.data,
                                    visible: isVisible
                                }
                            });
                            // Recursively apply to children of this child node
                            gatherChildNodesToToggle(childId, isVisible, nodesToUpdate);
                        }
                    });
                }
            };

            // Initialize the list of nodes to update with visibility toggles
            const nodesToUpdate = [];
            const isCurrentlyVisible = node.data.childIds?.some((childId) => nodeMap.get(childId)?.data.visible);

            // Collect all nodes to toggle and then update state in one batch
            gatherChildNodesToToggle(node.id, !isCurrentlyVisible, nodesToUpdate);
            setNodes((prevNodes) =>
                prevNodes.map((n) => nodesToUpdate.find((updatedNode) => updatedNode.id === n.id) || n)
            );
        },
        [nodes, setNodes]
    );

    const fetchCorporateData = async () => {
        const data = await fetchOrganizationsAndValueChains(selectedRow.id);

        const {newNodes, newEdges, center} = createCorporateNodesAndEdges(
            selectedRow.corporate,
            data.organizations,
            data.orgEmissions,
            data.valueChainEmissions,
            data.otherEmissions
        );

        setNodes(newNodes);
        setEdges(newEdges);

        centerDiagram(center);
    }

    const fetchOrgData = async () => {
        const data = await fetchValueChains(selectedRow.id);

        const {newNodes, newEdges, center} = createOrgNodesAndEdges(
            selectedRow.organization,
            data.valueChainEmissions,
            data.otherEmissions
        );

        setNodes(newNodes);
        setEdges(newEdges);

        centerDiagram(center);
    }

    const fetchValueChainData = async () => {
        const data = await fetchValueChainEmissions(selectedRow.id);

        const {newNodes, newEdges, center} = createValueChainNodesAndEdges(
            selectedRow.product_service,
            data.emissions,
        );

        setNodes(newNodes);
        setEdges(newEdges);

        centerDiagram(center);
    }

    const centerDiagram = (center) => {
        const zoomLevel = 1.35;

        const viewportHeight = 500;

        const translateX = containerWidth / 2 - center.x * zoomLevel;
        const translateY = viewportHeight / 2 - center.y * zoomLevel;

        setViewport({x: translateX, y: translateY, zoom: zoomLevel});
    }

    useEffect(() => {
        if (selectedRow) {
            const fetchData = async () => {
                if (pageType === 'corporate') {
                    await fetchCorporateData();
                } else if (pageType === 'organization') {
                    await fetchOrgData();
                } else if (pageType === 'valueChain') {
                    await fetchValueChainData();
                } else {
                    console.log('Invalid Page Type', pageType);
                }
            };

            fetchData();
        } else {
            setNodes([]);
            setEdges([]);
        }
    }, [selectedRow, containerWidth, setViewport]);

    return (
        <div ref={containerRef} style={{height: '500px', width: '100%'}}>
            <ReactFlow
                nodes={nodes.filter((node) => node.data.visible)}
                edges={edges.filter(
                    (edge) =>
                        nodes.find((n) => n.id === edge.source)?.data.visible &&
                        nodes.find((n) => n.id === edge.target)?.data.visible
                )}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeClick={onNodeClick}
                nodeTypes={nodeTypes}
                defaultEdgeOptions={{type: 'straight'}}
                fitView
            >
            </ReactFlow>
        </div>
    );
}

const StructuralViewWrapper = (props) => (
    <div className="diagram-container">
        <ReactFlowProvider>
            <StructuralView {...props} />
        </ReactFlowProvider>
    </div>
);

export default StructuralViewWrapper;

// export default StructuralView;